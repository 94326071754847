import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useRouter } from "next/router";
import * as Yup from "yup";

import { KaitoInput } from "@/components/Input";
import { Privacy } from "@/components/Privacy";
import { TermAndConditionModal } from "@/components/TermAndCondition";

import AccountBadge from "./AccountBadge";
import AccountBasic from "./AccountBasic";
import AccountFooter from "./AccountFooter";

// requirements: https://kaito-ai.slack.com/archives/C051DAJDHFU/p1691660752435949
const blockedEmailDomain = [
  "gmail.com",
  "hotmail.com",
  "yahoo.com",
  "yahoo.co.jp",
  "icloud.com",
  "126.com",
  "163.com",
  "qq.com",
  "edu",
  "aol.com",
  "protonmail.com",
];

const useAccountEntry = () => {
  const r = useRouter();

  const initialValue = {
    email: "",
  };
  const submitHandler = async (value, { setSubmitting, setFieldError }) => {
    const callbackUrl = r.query.callbackUrl ?? "/";
    const referralCode = r.query.referralCode;
    const query = {
      email: value.email,
      callbackUrl,
      referralCode,
    };
    try {
      await axios.get(`/api/portal/get_email?email=${encodeURIComponent(value.email)}`);
      // user found, jump to login page
      await r.push({
        pathname: "/auth/signin",
        query,
      });
    } catch (e) {
      // user not found, jump to create user page
      if (e.response.status === 404 || e.response.status === 401) {
        // TODO: revert it after 9-13 meeting
        // await r.push(`/auth/signup?email=${value.email}`);
        await r.push({
          pathname: "/auth/temp_verify",
          query,
        });
        return;
      }
    } finally {
      setSubmitting(false);
    }
  };

  const validateionSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("This field is required."),
    /*.test(
        "blockedEmailDomain",
        "Personal email is not supported now. Please use your business email address.",
        (v) => {
          return v && !blockedEmailDomain.some((d) => v.endsWith(d)); // return a false to throw error
        }
      ),*/
  });

  return (
    <Formik
      validateOnChange={true}
      validateOnMount={true}
      initialValues={initialValue}
      onSubmit={submitHandler}
      validationSchema={validateionSchema}
    >
      {({ isValid, isSubmitting }) => {
        let body = (
          <>
            <Box w="475px">
              <Form>
                <Box color={"white"} fontSize="32px" fontWeight={"600"}>
                  <AccountBadge />
                  Welcome, <br /> Kaito Pioneer!
                </Box>
                <Field name="email">
                  {({ field, form }) => {
                    return (
                      <FormControl mt="48px" isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel color="whiteAlpha.800">Email</FormLabel>
                        <KaitoInput {...field} placeholder="Enter your email address" />
                        <FormErrorMessage color={"#FF4B4B"} fontSize="14px">
                          {form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Button
                  mt="24px"
                  isLoading={isSubmitting}
                  isDisabled={!isValid}
                  colorScheme="brand"
                  type={"submit"}
                  w="475px"
                  h="40px"
                >
                  Continue with email
                </Button>
                <Box
                  color={"rgba(255, 255, 255, 0.60)"}
                  fontSize="14px"
                  lineHeight={"20px"}
                  mt="24px"
                >
                  {`By clicking "Continue with email" above, you acknowledge that you have read and
                  understood, and agree to our`}{" "}
                  <TermAndConditionModal
                    styles={{ display: "inline-block", textDecorationLine: "underline" }}
                  />{" "}
                  and
                  <Privacy
                    styles={{ display: "inline-block", ml: 1, textDecorationLine: "underline" }}
                  />
                  .
                </Box>
              </Form>
            </Box>
          </>
        );
        return (
          <Flex flex={1} flexDir="column">
            <Flex flex={1} alignItems={"center"} justifyContent="center">
              {body}
            </Flex>
            <AccountFooter />
          </Flex>
        );
      }}
    </Formik>
  );
};

const Entry = () => {
  return <AccountBasic innerComponent={useAccountEntry()} />;
};
export default Entry;
