import { Flex } from "@chakra-ui/react";

const AccountBadge = () => {
  return (
    <Flex
      px={2}
      py={1}
      borderRadius={"4px"}
      color="rgba(255, 255, 255, 0.6)"
      bg="rgba(255, 255, 255, 0.1)"
      fontSize={"16px"}
      fontWeight="500"
      width={"107px"}
    >
      MetaSearch
    </Flex>
  );
};

export default AccountBadge;
