import AccountEntry from "@/components/Account/AccountEntry";

import type { NextPageWithLayout } from "../_app";

const Entry: NextPageWithLayout = () => <AccountEntry />;

Entry.getLayout = (page) => {
  return page;
};

export default Entry;
